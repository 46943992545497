import {
  Show,
  TextField,
  SimpleShowLayout,
  ReferenceField,
} from "react-admin";
import {
  CreatedAndUpdatedField,
} from "../components/CustomFields";
import { DeleteForAdminToolbar } from "../components/CustomToolbars";

const SmartbinShow = () => {
  return (
    <Show actions={<DeleteForAdminToolbar />}>
      <SimpleShowLayout>
        <TextField label={"common.fields.id"} source={"EPCSerieID"} />
        <TextField
          label={"resources.epcserie.fields.wildcard"}
          source={"EPCWildcard"}
        />
        <ReferenceField
          label={"common.fields.paymentProvider"}
          source={"paymentProviderID"}
          reference="paymentProvider"
          link={false}
        >
          <TextField source={"name"} />
        </ReferenceField>
      </SimpleShowLayout>
      <CreatedAndUpdatedField />
    </Show>
  );
};

export default SmartbinShow;
