import {
  Create,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  useTranslate,
} from "react-admin";
import Grid from "@mui/material/Grid";
import { isUUID } from "../helpers/validators";
import { CancelButtonToolbar } from "../components/CustomToolbars";

const SmartmatCreate = (record: any) => {
  const translate = useTranslate();
  const posTip = translate(`resources.smartmats.tips.posid`);
  const idTip = translate("resources.smartmats.tips.id");
  const howtoTip = translate("resources.smartmats.tips.howto");
  const nameTip = translate("common.tips.devicename");

  return (
    <Create redirect="list">
      <SimpleForm toolbar={<CancelButtonToolbar />}>
        <Grid container spacing={1}>
          <div style={{ margin: "0.5em", padding: "1em", background: "#ffd" }}>
            {howtoTip}
          </div>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput
              source={"deviceID"}
              label={"common.fields.id"}
              title={idTip}
              required
              fullWidth
              // @ts-ignore
              validate={isUUID}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              label={"common.fields.remotePosID"}
              source={"remotePosID"}
              title={posTip}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput source={"eventID"} reference={"event"}>
              <SelectInput
                label={"common.fields.event"}
                optionText={"name"}
                fullWidth
                required
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              label={"common.fields.name"}
              source={"name"}
              title={nameTip}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default SmartmatCreate;
