/* eslint react/jsx-key: off */
import {
	Datagrid,
	List,
	ShowButton,
	TextField,
	useTranslate
} from "react-admin";

export const PackageTypesList = ({ ...props }) => {
  const translate = useTranslate();
  return (
    <List
      title={translate(`resources.packageTypes.name`, { smart_count: 2 })}
      {...props}
    >
      <Datagrid>
        <TextField label={"common.fields.name"} source={"name"} />
        <TextField label={"common.fields.id"} source={"packageTypeID"} />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default PackageTypesList;
