import * as React from "react";
import SmartbinIcon from "./SmartbinIcon";
import SmartbinList from "./SmartbinList";
import SmartbinShow from "./SmartbinShow";
import SmartbinCreate from "./SmartbinCreate";
import SmartbinEdit from "./SmartbinEdit";

export default {
  list: SmartbinList,
  create: SmartbinCreate,
  edit: SmartbinEdit,
  show: SmartbinShow,
  icon: SmartbinIcon,
};
