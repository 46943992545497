import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  EditButton,
  usePermissions,
} from "react-admin";
import UserShow from "./UserShow";
import { AUTH_ROLES } from "../constants/auth";

const UserList = () => {
  const p = usePermissions();

  return (
    <List perPage={25} sort={{ field: "date", order: "desc" }}>
      <Datagrid
        rowClick="expand"
        expand={<UserShow />}
        sx={{
          "& .column-customer_id": {
            display: { xs: "none", md: "table-cell" },
          },
          "& .column-total_ex_taxes": {
            display: { xs: "none", md: "table-cell" },
          },
          "& .column-delivery_fees": {
            display: { xs: "none", md: "table-cell" },
          },
          "& .column-taxes": {
            display: { xs: "none", md: "table-cell" },
          },
        }}
      >
        <TextField label={"common.fields.name"} source="displayName" />
        <TextField label="resources.users.fields.email" source="email" />
        <ReferenceField source="roleId" reference="groups" link={"show"}>
          <TextField source={"name"} />
        </ReferenceField>
        <TextField
          label="resources.users.fields.company"
          source="company.name"
        />
        <DateField label="common.fields.updated" source="updatedAt" />
        {p.isLoading == false && p.permissions == AUTH_ROLES.ADMIN && (
          <EditButton />
        )}
      </Datagrid>
    </List>
  );
};

export default UserList;
