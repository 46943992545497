/* eslint react/jsx-key: off */
import * as React from "react";
import {
  useTranslate,
  Datagrid,
  List,
  TextField,
  ShowButton,
  TextInput,
  Filter,
  DateField,
  usePermissions,
} from "react-admin";
import { EventTimeStatusField } from "../components/CustomFields";
import { AUTH_ROLES } from "../constants/auth";

const EventFilters = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source={"name"} />
      <TextInput source={"eventID"} />
    </Filter>
  );
};
//        filters={<EventFilters />}

const EventList = ({ ...props }) => {
  const p = usePermissions();
  const translate = useTranslate();
  const title = translate(`resources.events.name`, { smart_count: 2 });

  if (p.isLoading == false && p.permissions == AUTH_ROLES.ADMIN) {
    return (
      <List title={title} {...props}>
        <Datagrid>
          <TextField label={"common.fields.name"} source={"name"} />
          <TextField label={"common.fields.id"} source={"eventID"} />
          <EventTimeStatusField
            label="resources.events.fields.live"
            source="eventTimeStatus"
          />
          <DateField
            label="resources.events.fields.startDate"
            source="startTime"
            showTime={true}
          />
          <DateField
            label="resources.events.fields.endDate"
            source="endTime"
            showTime={true}
          />
          <ShowButton />
        </Datagrid>
      </List>
    );
  } else {
    return (
      <List title={title} {...props}>
        <Datagrid>
          <TextField label={"common.fields.name"} source={"name"} />
          <TextField label={"common.fields.id"} source={"eventID"} />
          <EventTimeStatusField
            label="resources.events.fields.live"
            source="eventTimeStatus"
          />
          <DateField
            label="resources.events.fields.startDate"
            source="startTime"
            showTime={true}
          />
          <DateField
            label="resources.events.fields.endDate"
            source="endTime"
            showTime={true}
          />
          <ShowButton />
        </Datagrid>
      </List>
    );
  }
};

export default EventList;
