import * as React from "react";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import CashierList from "./CashierList";
import CashierShow from "./CashierShow";
import CashierCreate from "./CashierCreate";
import CashierEdit from "./CashierEdit";
export default {
  list: CashierList,
  create: CashierCreate,
  edit: CashierEdit,
  show: CashierShow,
  icon: PointOfSaleIcon,
};
