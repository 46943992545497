import * as React from "react";
import SmartbinIcon from "./EPCSerieIcon";
import SmartbinList from "./EPCSerieList";
import SmartbinShow from "./EPCSerieShow";
import SmartbinCreate from "./EPCSerieCreate";
import SmartbinEdit from "./EPCSerieEdit";

export default {
  list: SmartbinList,
  create: SmartbinCreate,
  edit: SmartbinEdit,
  show: SmartbinShow,
  icon: SmartbinIcon,
};
