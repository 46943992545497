export const USERS = 'users';
export const GROUPS = 'groups';
export const EVENT = 'event';
export const SMARTBIN = 'smartbin';
export const SMARTBINTYPE = 'smartbintype';
export const SMARTMAT = 'smartmat';
export const CASHIER = 'cashier';
export const PACKAGE_TYPE = 'packagetype';
export const PAYMENT_PROVIDER = 'paymentprovider';
export const PRODUCT = 'product';
export const EPC_SERIE = 'epcserie';
export const PACKAGE_RELATION = 'packagerelation';
export const CARD_TX = 'cardTX';
export const PACKAGES = 'package';