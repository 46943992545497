import * as React from 'react';
import { useRecordContext, useTranslate, ReferenceField, TextField, DateField } from 'react-admin';
import NoSyncIcon from "@mui/icons-material/SyncProblem";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckIcon from '@mui/icons-material/Check';

// @ts-ignore
export const TimestampField = ({ record = {}, ...props }) => <span>{(record[props.source]===undefined ? 'none' : new Date(record[props.source]*1000).toUTCString())}</span>;
// @ts-ignore
export const UserLink = ({ record = {}, ...props }) => <span>{(record[props.source]===undefined ? '' : <a href={'/#/users/' + record[props.source] + '/show'}>{record[props.source]}</a> )}</span>;
export const EventTimeStatusField = (props:any) => {
	let currentStatus = {
			status: 'Ended',
			color: 'red',
	};

	const record = useRecordContext();
	switch (record[props.source]) {
		case 'ongoing':
			currentStatus.color = 'green';
			currentStatus.status = 'Ongoing'
			break;
		case 'ended':
			currentStatus.color = 'red';
			currentStatus.status = 'Ended'
			break;
		case 'future':
			currentStatus.color = 'orange';
			currentStatus.status = 'Planned'
			break;	
	}

	return (
		<span>
				<img
						src={`/img/btn_${currentStatus.color}.svg`}
						width="16em"
						title={currentStatus.status}
						alt={currentStatus.status}
				/>
		</span>
	);
};
export const CapacityField = ( label:any ) => {
	const record = useRecordContext();
	const capacity = record['usedCapacity'];
	const percentage = record['capacityPercentage'];
	let statuscolor = 'green'
	if (percentage > 50)
		statuscolor = 'orange'
	else if (percentage > 75)
	statuscolor = 'red'
	if (record['active']==false)
		return (<span title='No Sync'><NoSyncIcon /></span>)
	return (<span><img src={'/img/btn_' + statuscolor + '.svg'} width='16em' title={capacity + ' - ' + percentage + '%'}/></span>);
};
export const CreatedAndUpdatedField = () => {
	const translate = useTranslate();
	const record = useRecordContext();

	const getUserLink = (userId:any) => {
		if (userId == null || userId == '') {
			return translate('common.words.unknown');
		}
		return (
			<ReferenceField source="createdBy" reference="users" link={'show'} record={record}>
				<TextField source="displayName"/>
			</ReferenceField>
		)
	}

	return (
		<span style={{marginTop: '0.8em', position:'absolute',fontSize: '0.9em', color: 'gray'}}>
			{translate('common.fields.created')}&nbsp;<DateField source="createdAt" />&nbsp;{translate('common.words.by')}&nbsp;{getUserLink(record['createdBy'])}&nbsp;-&nbsp;
			{translate('common.fields.updated')}&nbsp;<DateField source="updatedAt" />&nbsp;{translate('common.words.by')}&nbsp;{getUserLink(record['updatedBy'])}
		</span>
	);
};
export const IsActiveField = ( props:any ) => {
	const record = useRecordContext();
	if (record[props.source] == true) {
		return (<span><img src={'/img/btn_green.svg'} width='16em' title='Online' /></span>);
	}
	return (<span><img src={'/img/btn_red.svg'}	width='16em' title='Offline' /></span>);
};

export const IsAssignedField = (label:any) => {
	const record = useRecordContext();
	if (!record.eventID) {
			return <WarningAmberIcon color="warning" />;
	}
	return <CheckIcon color="success" />;
};
