/* eslint react/jsx-key: off */
import { Edit, required, SimpleForm, TextInput } from "react-admin";
import { maxLength, minLength } from "ra-core";
import Grid from "@mui/material/Grid";
import { CancelButtonToolbar } from "../components/CustomToolbars";

const validateName = [required(), minLength(1), maxLength(40)];

const PackageTypeEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm redirect="list" toolbar={<CancelButtonToolbar />}>
        <Grid container>
          <TextInput
            label={"common.fields.id"}
            source={"packageTypeID"}
            fullWidth={true}
            disabled
          />
          <TextInput
            label={"common.fields.name"}
            source={"name"}
            validate={validateName}
            fullWidth={true}
          />
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default PackageTypeEdit;
