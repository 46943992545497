/* eslint react/jsx-key: off */
import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import Grid from "@mui/material/Grid";
import { paymentProviders } from "../constants/paymentProviders";
import { CancelButtonToolbar } from "../components/CustomToolbars";

const CashierEdit = () => {
  return (
    <Edit>
      <SimpleForm redirect="show" toolbar={<CancelButtonToolbar />}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput
              label={"common.fields.name"}
              source={"name"}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput source={"eventID"} reference={"event"}>
              <SelectInput
                label={"common.fields.event"}
                optionText={"name"}
                required
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label={"common.fields.paymentProvider"}
              source={"paymentProviderID"}
              type={"number"}
              required
              fullWidth={true}
              defaultValue={"1"}
              choices={paymentProviders.choices}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              source={"cashierRemoteID"}
              label={"resources.cashier.fields.cashierRemoteID"}
              required
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default CashierEdit;
