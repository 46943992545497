import { defaultTheme } from "react-admin";

/*
Goodless colors
#5187f2 - primary #473198 - secondary - #F0F000 contrast - border #4f3cc9
#F25187
#51F2BC
#F2BC51
*/

export const darkTheme = {
  palette: {
    primary: {
      main: "#5187f2",
    },
    secondary: {
      main: "#101b30",
    },
    mode: "dark" as "dark", // Switching the dark mode on is a single property value change.
  },
  sidebar: {
    width: 200,
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: "3px solid #000",
          "&.RaMenuItemLink-active": {
            borderLeft: "3px solid #90caf9",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: "#ffffffb3",
          backgroundColor: "#616161e6",
        },
      },
    },
  },
};

export const lightTheme = {
  palette: {
    primary: {
      main: "#5187f2",
    },
    secondary: {
      light: "#5f5fc4",
      main: "#294479",
      dark: "#001064",
      contrastText: "#ffffff",
    },
    background: {
      default: "#f0f0f0",
    },
    mode: "light" as "light",
  },
  tipBlock: {
    margin: "0.5em",
    padding: "1em",
    background: "#ffd",
  },
  shape: {
    borderRadius: 10,
  },
  sidebar: {
    width: 200,
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: "3px solid #fff",
          "&.RaMenuItemLink-active": {
            borderLeft: "3px solid #F0F000",
            background: "#fff",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: "none",
        },
        root: {
          //border: '1px solid #e0e0e3',
          backgroundClip: "padding-box",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: "#202020",
          backgroundColor: "#5187f2",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#f5f5f5",
        },
        barColorPrimary: {
          backgroundColor: "#d7d7d7",
        },
      },
    },
  },
};
