import * as React from "react";
import PackageTypeIcon from './PackageTypeIcon';
import PackageTypeList from './PackageTypeList';
import PackageTypeShow from './PackageTypeShow';
import PackageTypeCreate from './PackageTypeCreate';
import PackageTypeEdit from './PackageTypeEdit';

export default {
  list: PackageTypeList,
	show: PackageTypeShow,
  create: PackageTypeCreate,
  edit: PackageTypeEdit,
  icon: PackageTypeIcon,
};
