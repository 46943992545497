import {
  Show,
  TextField,
  SimpleShowLayout,
  ReferenceField,
  SelectField,
} from "react-admin";
import {
  CreatedAndUpdatedField,
  IsActiveField,
  CapacityField,
} from "../components/CustomFields";
import { binTypes } from "../constants/binTypes";
import { DeleteForAdminToolbar } from "../components/CustomToolbars";

const SmartbinShow = () => {
  return (
    <Show actions={<DeleteForAdminToolbar />}>
      <SimpleShowLayout>
        <TextField label={"common.fields.id"} source={"SID"} />
        <TextField label={"common.fields.name"} source={"name"} />
        <SelectField
          label={"common.fields.type"}
          source={"smartbinTypeId"}
          choices={binTypes.choices}
        />
        <ReferenceField source={"eventID"} reference={"event"} link={"show"}>
          <TextField source={"name"} label={"common.fields.event"} />
        </ReferenceField>
        <IsActiveField label={"common.fields.online"} source={"active"} />
        <CapacityField
          label="resources.smartbins.fields.capacity"
          source="usedCapacity"
        />
        <TextField label={"common.fields.error"} source={"errorMessage"} />
      </SimpleShowLayout>
      <CreatedAndUpdatedField />
    </Show>
  );
};

export default SmartbinShow;
