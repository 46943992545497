import { TranslationMessages } from "react-admin";
import englishMessages from "ra-language-english";

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,

  pos: {
    search: "Search",
    configuration: "Configuration",
    language: "Language",
    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark",
    },
    menu: {
      users: "Users",
      devices: "Devices",
      packages: "Packages",
      support: "Support",
    },
  },
  common: {
    messages: {
      noaccess: "No Access!",
      notadmin: "You need to be an Administrator to do this",
      discard_changes: "Discard changes",
      quit_without_save: "Are you sure you want to quit without saving?",
      not_uuid: "Invalid UUID4",
    },
    tips: {
      devicename:
        "Name of device is a free text field. You can choose anything to make this device familiar to you.",
    },
    button: {
      create: "Create",
      show: "Show",
      edit: "Edit",
      save: "Save",
      delete: "Delete",
      cancel: "Cancel",
    },
    words: {
      by: "by",
      from: "from",
      to: "to",
      unknown: "unknown",
    },
    fieldGroups: {
      summary: "Summary",
      identity: "Identity",
      address: "Address",
      security: "Security",
      company: "Company",
    },
    fields: {
      id: "ID",
      name: "Name",
      type: "Type",
      created: "Created",
      updated: "Updated",
      paymentProvider: "Payment Provider",
      event: "Event",
      smartbin: "Smartbin",
      smartmat: "Smartmat",
      cashier: "Cashier",
      packageType: "Package Type",
      epcSerieId: "EPC Serie Id",
      productId: "Product Id",
      topupValue: "Top-up Value",
      feederID: "Feeder Id",
      online: "Online",
      time: "Time",
      error: "Error Message",
      remotePosID: "Point of Sale",
    },
  },
  resources: {
    epcserie: {
      name: "EPC Series",
      fields: {
        wildcard: "Wildcard",
      },
    },
    packages: {
      name: "Packages",
      activatedAt: "Activated at",
      EPC: "EPC",
      status: "Status",
      returnedAt: "Returned at",
      priceAmount: "Price Amount",
      cardID: "Card ID",
      returnSmartbin: "Return Smartbin",
    },
    cardTX: {
      name: "Card Transactions",
      transactionID: "Transaction ID",
      cardID: "Card ID",
      cardUID: "Card UID",
      time: "Time",
      GuthabenAlt: "Previous Balance",
      Betrag: "Amount",
      GuthabenNeu: "New Balance",
      Type: "Type",
    },
    users: {
      name: "User |||| Users",
      fields: {
        name: "Name",
        email: "E-Mail",
        phone: "Phone",
        role: "Role",
        active: "Active",
        firstname: "First name",
        lastname: "Last name",
        company: "Company",
        address: "Address",
        place: "Place",
        zip: "Zip code",
        country: "Country",
        www: "Website",
        password: "Password",
        confirm_password: "Confirm password",
      },
      filters: {
        last_visited: "Last visited",
        today: "Today",
        this_week: "This week",
        last_week: "Last week",
        this_month: "This month",
        last_month: "Last month",
        earlier: "Earlier",
      },
      fieldGroups: {
        identity: "Identity",
        address: "Address",
        stats: "Stats",
        history: "History",
        password: "Password",
        change_password: "Change Password",
      },
      page: {
        delete: "Delete User",
      },
      errors: {
        password_unsecure:
          "Password must be minimum 9 characters long and contain a small, big letter and one of special characters: #, !, $, /, _, |, -, &",
        password_required: "Please confirm your password",
        password_mismatch: "Passwords do not match",
      },
    },
    events: {
      name: "Event |||| Events",
      fields: {
        id: "Event ID",
        name: "Name",
        live: "Live",
        startDate: "Start Date",
        endDate: "End Date",
        smartbinCount: "Smartbin count",
        cashierCount: "Cashier count",
        smartbinList: "List of smartbins",
        smartmatList: "List of smartmats",
        cashierList: "List of cashiers",
        paymentMetadata: "Organizer Topup card",
        paymentProvider: "Payment Provider",
        paymentEventId: "Payment Provider Event Id",
      },
      tabs: {
        smartbins: "Smartbins",
        smartmats: "Smartmats",
        cashiers: "Cashiers",
        packagerelations: "Package Relations",
      },
      labels: {
        choose: "Please, choose the event or create your own",
        maborPackageTypes: "Add package types",
        duration: "Duration",
        empty: "It is empty here, please add some events",
      },
      actions: {
        delete: "Delete event",
      },
      messages: {
        notDeleted: "Event was not deleted",
      },
    },
    packageTypes: {
      name: "Type |||| Types",
      messages: {
        cantCreate: "Could not create package type",
      },
      labels: {
        choose: "Please, choose the event or create your own",
        maborPackageTypes: "Add mabor package types",
        empty: "There are no package types at the moment",
      },
    },
    packageRelations: {
      name: "Relation |||| Relations",
      messages: {
        cantCreate: "Could not create package relation",
      },
    },
    smartbins: {
      name: "Smartbin |||| Smartbins",
      messages: {
        uuidInValid: "Invalid UUID4",
        cantCreate: "Could not create smartbin",
      },
      fields: {
        capacity: "Capacity",
      },
      tips: {
        id: "Smartbin ID is printed on the back side of electronics box inside the device. It is in UUIDv4 format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
        bintype:
          "Bins are manufactured in two versions. Mobilebin (on wheels) and Smartbin with sorting mechanism. Choose appropriate type.",
        howto:
          "You are about to initialize a new Smartbin device. Please prepare following data: Smartbin ID (printed on the backside of Electronics box). Select type of Bin and assign a recognizable name.",
      },
    },
    smartmats: {
      name: "Smartmat |||| Smartmats",
      tips: {
        id: "Smartmat ID is printed on the bottom side of device. It is in UUIDv4 format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
        posid:
          "A MAC address of Starnet Payment Terminal. Please consult Starnet to learn how to discover MAC address of their Payment Terminal.",
        howto:
          "You are about to initialize a new Smartmat device. Prepare following information: Smartmat ID = a UUIDv4 formatted Id found on the bottom of the device, Starnet MAC address of payment terminal. Name of Smartmat is free text and you can choose anything to make this mat familiar to you.",
      },
    },
    cashier: {
      name: "POS Terminal |||| POS Terminals",
      messages: {
        cantCreate: "Could not add POS Terminal",
      },
      fields: {
        cashierRemoteID: "POS Terminal MAC address",
      },
      tips: {
        cashierRemoteID:
          "a MAC address of POS terminal (visible on its screen top bar)",
      },
    },
  },
};

export default customEnglishMessages;
