import * as React from "react";
import {
  Datagrid,
  List,
  TextField,
  ShowButton,
  ReferenceField,
} from "react-admin";

const SmartbinList = () => {
  return (
    <List>
      <Datagrid>
        <TextField label="common.fields.id" source="EPCSerieID" />
        <TextField
          label="resources.epcserie.fields.wildcard"
          source="EPCWildcard"
        />
        <ReferenceField
          label={"common.fields.paymentProvider"}
          source={"paymentProviderID"}
          reference="paymentProvider"
          link={false}
        >
          <TextField source={"name"} />
        </ReferenceField>
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default SmartbinList;
