export const AUTH_ROLES = {
  ADMIN: "admin",
  SERVICE: "service",
  USER: "user",
  EXTERN: "extern",
  GUEST: "guest",
};

export const RoleId = {
  ADMIN: 1,
  SERVICE: 2,
  USER: 3,
  EXTERN: 4,
  GUEST: 5,
};

export const ALL_ADMIN_ROLES = [AUTH_ROLES.ADMIN];
export const ALL_USERS_ROLES = [AUTH_ROLES.ADMIN, AUTH_ROLES.USER];
export const ALL_ROLES = [AUTH_ROLES.ADMIN, AUTH_ROLES.USER, AUTH_ROLES.GUEST];
