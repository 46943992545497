/* eslint react/jsx-key: off */
import {
  Create,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  useTranslate,
} from "react-admin";
import Grid from "@mui/material/Grid";
import { isUUID } from "../helpers/validators";
import { CancelButtonToolbar } from "../components/CustomToolbars";
import { binTypes } from "../constants/binTypes";

const SmartbinCreate = (record: any) => {
  const translate = useTranslate();
  const nameTip = translate("common.tips.devicename");
  const idTip = translate("resources.smartbins.tips.id");
  const binTip = translate("resources.smartbins.tips.bintype");
  const howtoTip = translate("resources.smartbins.tips.howto");

  return (
    <Create redirect={"list"}>
      <SimpleForm toolbar={<CancelButtonToolbar />}>
        <Grid container spacing={1}>
          <div style={{ margin: "0.5em", padding: "1em", background: "#ffd" }}>
            {howtoTip}
          </div>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput
              source={"SID"}
              label={"common.fields.id"}
              title={idTip}
              required
              fullWidth
              // @ts-ignore
              validate={isUUID}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label={"common.fields.type"}
              source={"smartbinTypeId"}
              type={"number"}
              required
              defaultValue={"1"}
              fullWidth
              title={binTip}
              choices={binTypes.choices}
            />
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput source={"eventID"} reference={"event"}>
              <SelectInput
                label={"common.fields.event"}
                optionText={"name"}
                required
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              label={"common.fields.name"}
              source={"name"}
              title={nameTip}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default SmartbinCreate;
