
const Smartbin = { id: 1, name: 'Smartbin' };
const Mobilebin = { id: 2, name: 'Mobilebin' };

export const binTypes = {
	choices: [
		Smartbin,
		Mobilebin,
	]
};
