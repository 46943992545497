import {
	ReferenceField,
	Show,
	SimpleShowLayout,
	TextField
} from "react-admin";
import { CreatedAndUpdatedField } from "../components/CustomFields";
import { DeleteForAdminToolbar } from "../components/CustomToolbars";

const PackageRelationShow = () => {
  return (
    <Show actions={<DeleteForAdminToolbar />}>
      <SimpleShowLayout>
        <TextField label={"common.fields.id"} source={"id"} />
        <TextField label={"common.fields.epcSerieId"} source={"EPCSerieID"} />
        <ReferenceField source={"eventID"} reference={"event"} link={"show"}>
          <TextField source={"name"} label={"common.fields.event"} />
        </ReferenceField>
        <ReferenceField
          source={"packageTypeID"}
          reference={"packagetype"}
          link={"show"}
        >
          <TextField source={"name"} label={"common.fields.packageType"} />
        </ReferenceField>
        <TextField label={"common.fields.productId"} source={"productID"} />
        <TextField label={"common.fields.topupValue"} source={"topupValue"} />
        <TextField label={"common.fields.feederID"} source={"feederID"} />
      </SimpleShowLayout>
      <CreatedAndUpdatedField />
    </Show>
  );
};

export default PackageRelationShow;
