/* eslint react/jsx-key: off */
import {
  Create,
  TextInput,
  SimpleForm,
  minLength,
  maxLength,
} from "react-admin";
import Grid from "@mui/material/Grid";
import { CancelButtonToolbar } from "../components/CustomToolbars";

const validateName = [minLength(1), maxLength(40)];

const PackageTypeCreate = (record: any) => {
  return (
    <Create redirect="list">
      <SimpleForm toolbar={<CancelButtonToolbar />}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              source={"name"}
              label={"common.fields.name"}
              fullWidth={true}
              validate={validateName}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default PackageTypeCreate;
