//import * as process from 'process';
import { AuthProvider } from "react-admin";


//const AuthSrvName = process.env.REACT_APP_AUTH_SERVER_PATH;

const AuthSrvName = 'https://auth.goodless.dev/auth';

const authProvider: AuthProvider = {
  login: ({ username, password }) => {
    const request = new Request(AuthSrvName, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => response.json())
      .then((body) => {
        if (body !== undefined && body !== null && body.token) {
          localStorage.setItem("token", body.token);
          localStorage.setItem("userRole", body.role);
          localStorage.setItem("userName", body.displayName);
          localStorage.setItem("userId", body.userId);
          return Promise.resolve();
        }
        return Promise.reject();
      });
  },
  checkAuth: () => {
    const token = localStorage.getItem("token");
    if (token === undefined || token === null || token === "") {
      return Promise.reject();
    }
    const request = new Request(AuthSrvName + `/${token}`, {
      method: "GET",
    });
    return fetch(request)
      .then((response) => response.json())
      .then((body) => {
        if (body !== undefined && body !== null && body.token) {
          localStorage.setItem("token", body.token);
          localStorage.setItem("userRole", body.role);
          localStorage.setItem("userEmail", body.email);
          localStorage.setItem("userId", body.userId);
          return Promise.resolve();
        }
        localStorage.removeItem("token");
        localStorage.removeItem("userRole");
        localStorage.removeItem("userId");
        return Promise.reject();
      });
  },
  getPermissions: () => {
    const token = localStorage.getItem("token");
    if (token === undefined || token === null || token === "") {
      return Promise.reject();
    }
    const request = new Request(AuthSrvName + `/${token}`, {
      method: "GET",
    });
    return fetch(request)
      .then((response) => response.json())
      .then((body) => {
        if (body !== undefined && body !== null && body.role) {
          return Promise.resolve(body.role);
        }
        return Promise.reject();
      });
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userRole");
    localStorage.removeItem("userId");
    return Promise.resolve();
  },
  checkError: (error) => {
    if (error.status === 401 || error.status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
};

export default authProvider;
