/* eslint react/jsx-key: off */
import * as React from "react";
import { useFormContext } from "react-hook-form";
import {
  Create,
  TextInput,
  SimpleForm,
  minValue,
  number,
  minLength,
  maxLength,
  DateTimeInput,
  SelectInput,
} from "react-admin";
import { CancelButtonToolbar } from "../components/CustomToolbars";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { paymentProviders } from "../constants/paymentProviders";

const validateName = [minLength(3), maxLength(15)];
const validatePositiveNumber = [number(), minValue(0)];

const EventCreate = () => {
  const formatDate = (date: any) => {
    //debugger;
    if (typeof date === "number") return date;
    return +date;
  };

  const transform = (data: any) => {
    return {
      ...data,
      startTime: formatDate(data.startTime),
      endTime: formatDate(data.endTime),
      organizerPaymentMetadata: {
        cardID: +data.organizerPaymentMetadata,
      },
      organizerPaymentProvider: +data.organizerPaymentProvider,
      remoteEventID: data.remoteEventID,
    };
  };

  return (
    <Create redirect="list" transform={transform}>
      <SimpleForm toolbar={<CancelButtonToolbar />}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                label="resources.events.fields.name"
                source={"name"}
                fullWidth={true}
                required
                validate={validateName}
              />
            </Grid>
            <Grid item xs={4}>
              <DateTimeInput
                label="resources.events.fields.startDate"
                source="startTime"
                fullWidth={true}
                defaultValue={new Date()}
              />
            </Grid>
            <Grid item xs={4}>
              <DateTimeInput
                label="resources.events.fields.endDate"
                source="endTime"
                fullWidth={true}
                defaultValue={new Date().setDate(new Date().getDate() + 1)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="resources.events.fields.paymentMetadata"
                source="organizerPaymentMetadata"
                fullWidth={true}
                required
                validate={validatePositiveNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label="resources.events.fields.paymentProvider"
                source="organizerPaymentProvider"
                type={"number"}
                required
                fullWidth={true}
                defaultValue={"1"}
                choices={paymentProviders.choices}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                label="resources.events.fields.paymentEventId"
                source="remoteEventID"
                fullWidth={true}
                validate={validatePositiveNumber}
              />
            </Grid>
          </Grid>
        </Box>
      </SimpleForm>
    </Create>
  );
};

export default EventCreate;
