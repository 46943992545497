
const feederA = { id: 'A', name: 'A' };
const feederB = { id: 'B', name: 'B' };
const feederC = { id: 'C', name: 'C' };
const feederD = { id: 'D', name: 'D' };

export const feederTypes = {
	choices: [
		feederA,
		feederB,
		feederC,
		feederD,
	]
};
