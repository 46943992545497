import {
  Button,
  DeleteButton,
  EditButton,
  SaveButton,
  Toolbar,
  useRedirect,
  usePermissions,
  useRecordContext,
} from "react-admin";
import { AUTH_ROLES } from "../constants/auth";

export const CancelButtonToolbar = (props: any) => {
  const handleClick = () => redirect("../");
  const redirect = useRedirect();
  const record = useRecordContext();

  let saveButtonLabel = "common.button.create";
  if (record != undefined) saveButtonLabel = "common.button.save";

  return (
    <Toolbar {...props} sx={{ justifyContent: "space-between" }}>
      <SaveButton label={saveButtonLabel} />
      <Button
        variant={"outlined"}
        size={"medium"}
        onClick={handleClick}
        label={"common.button.cancel"}
      ></Button>
    </Toolbar>
  );
};

export const DeleteForAdminToolbar = () => {
  const { permissions } = usePermissions();
  const record = useRecordContext();
  const createdBy = record["createdBy"];
  let currentUserId = null;
  if (createdBy !== undefined && createdBy !== null && createdBy !== "") {
    currentUserId = localStorage.getItem("userId");
  }
  return (
    <Toolbar sx={{ display: "flex", justifyContent: "end" }}>
      <EditButton />
      {createdBy === currentUserId ||
        (permissions === AUTH_ROLES.ADMIN && <DeleteButton />)}
    </Toolbar>
  );
};
