/* eslint react/jsx-key: off */
import {
  Create,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  useTranslate,
} from "react-admin";
import Grid from "@mui/material/Grid";
import { CancelButtonToolbar } from "../components/CustomToolbars";

const SmartbinCreate = (record: any) => {
  const translate = useTranslate();
  return (
    <Create redirect={"list"}>
      <SimpleForm toolbar={<CancelButtonToolbar />}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput
              label="resources.epcserie.fields.wildcard"
              source="EPCWildcard"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput
              source={"paymentProviderID"}
              reference={"paymentProvider"}
            >
              <SelectInput
                label={"common.fields.paymentProvider"}
                optionText={"name"}
                required
                fullWidth
              />
            </ReferenceInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default SmartbinCreate;
