import { any } from "prop-types";
import { UUIDv4 } from "uuid-v4-validator";

var mainPw: string = "";

export function storePw(val: string) {
  mainPw = val;
  return undefined;
}

export const doesPwMatch = (value: any, allValues: any, props: any) => {
  if (value === mainPw) return undefined;
  return "resources.users.errors.password_mismatch";
};

export const isMatch = (val: string) =>
  val === mainPw ? undefined : "resources.users.errors.password_mismatch";

export const isMatchWarn = (str: string, val: string) => {
  return undefined;
};

export const isUUID = (val: string) => {
  if (UUIDv4.validate(val)) return undefined;
  return "common.messages.not_uuid";
};
