import { Show, TextField, SimpleShowLayout, ReferenceField } from "react-admin";
import {
  CreatedAndUpdatedField,
  IsActiveField,
} from "../components/CustomFields";
import { DeleteForAdminToolbar } from "../components/CustomToolbars";

const SmartmatShow = () => {
  return (
    <Show actions={<DeleteForAdminToolbar />}>
      <SimpleShowLayout>
        <TextField label={"common.fields.name"} source={"name"} />
        <TextField label={"common.fields.id"} source="deviceID" />
        <TextField label={"common.fields.remotePosID"} source={"remotePosID"} />
        <ReferenceField
          label={"common.fields.event"}
          source={"eventID"}
          reference={"event"}
          link={"show"}
        >
          <TextField source={"name"} />
        </ReferenceField>
        <IsActiveField label={"common.fields.online"} source={"active"} />
      </SimpleShowLayout>
      <CreatedAndUpdatedField />
    </Show>
  );
};

export default SmartmatShow;
