import * as React from 'react';
import EventIcon from '@mui/icons-material/Event';
import EventList from './EventList';
import EventShow from './EventShow';
import EventCreate from './EventCreate';
import EventEdit from './EventEdit';

export default {
    list: EventList,
    show: EventShow,
		create: EventCreate,
		edit: EventEdit,
		icon: EventIcon,
};
