/* eslint react/jsx-key: off */
import * as React from "react";
import {
  Edit,
  required,
  ShowButton,
  TopToolbar,
  usePermissions,
  SimpleForm,
  DateTimeInput,
  TextInput,
  SaveButton,
  Toolbar,
  useRedirect,
  number,
  minValue,
  useGetOne,
  SelectInput,
} from "react-admin";
import { maxLength, minLength, useRecordContext, useTranslate } from "ra-core";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ProgressBar } from "../helpers/ProgressBar";
import { paymentProviders } from "../constants/paymentProviders";

const EditActions = () => (
  <TopToolbar>
    <ShowButton />
  </TopToolbar>
);

const validateName = [required(), minLength(2), maxLength(15)];
const validatePositiveNumber = [number(), minValue(0)];

const EventEditToolbar = (props: any) => {
  /// confirm window functionality
  //const [open, setOpen] = useState(false);
  const handleClick = () => redirect("/event");
  const redirect = useRedirect();
  /// end confirm window

  const translate = useTranslate();

  return (
    <Toolbar {...props} sx={{ justifyContent: "space-between" }}>
      <SaveButton />
      <Button variant="outlined" onClick={handleClick}>
        {translate("common.button.cancel")}
      </Button>
    </Toolbar>
  );
};

const EventEditForm = () => {
  const { permissions } = usePermissions();
  return (
    <SimpleForm toolbar={<EventEditToolbar />} redirect="show">
      {permissions === "admin" && <TextInput disabled source={"id"} />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextInput
            source={"name"}
            fullWidth={true}
            validate={validateName}
            label={"common.fields.name"}
          />
        </Grid>
        <Grid item xs={4}>
          <DateTimeInput
            source="startTime"
            fullWidth={true}
            label="resources.events.fields.startDate"
          />
        </Grid>
        <Grid item xs={4}>
          <DateTimeInput
            source="endTime"
            fullWidth={true}
            label="resources.events.fields.endDate"
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="resources.events.fields.paymentMetadata"
            source="organizerPaymentMetadata.cardID"
            fullWidth={true}
            required
            validate={validatePositiveNumber}
          />
        </Grid>

        <Grid item xs={6}>
          <SelectInput
            label={"common.fields.paymentProvider"}
            source="organizerPaymentProvider"
            type={"number"}
            required
            fullWidth={true}
            defaultValue={"1"}
            choices={paymentProviders.choices}
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            label="resources.events.fields.paymentEventId"
            source="remoteEventID"
            fullWidth={true}
            validate={validatePositiveNumber}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
const UserEdit = () => {
  const { id } = useParams();
  const [isGetOneError, setIsGetOneError] = useState(false);

  const { data } = useGetOne(
    "event",
    { id },
    {
      onError: (err) => {
        setIsGetOneError(true);
        console.log(err);
      },
    }
  );

  const transform = (data2: any) => {
    return {
      name: data2.name,
      startTime: Date.parse(data2.startTime),
      endTime: Date.parse(data2.endTime),
      organizerPaymentMetadata: {
        cardID: data2.organizerPaymentMetadata.cardID,
      },
      organizerPaymentProvider: data2.organizerPaymentProvider,
      remoteEventID: data2.remoteEventID,
    };
  };

  return !data ? (
    <ProgressBar />
  ) : (
    <Box>
      <Edit title={data.name} actions={<EditActions />} transform={transform}>
        <EventEditForm />
      </Edit>
    </Box>
  );
};

export default UserEdit;
