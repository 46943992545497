/* eslint react/jsx-key: off */
import {
	Datagrid,
	List,
	ReferenceField,
	ShowButton,
	TextField,
	useTranslate
} from "react-admin";

export const PackageRelationsList = ({ ...props }) => {
  const translate = useTranslate();
  return (
    <List
      title={translate(`resources.packageRelations.name`, { smart_count: 2 })}
      {...props}
    >
      <Datagrid>
        <TextField label={"common.fields.id"} source={"packageRelationID"} />
        <TextField label={"common.fields.epcSerieId"} source={"EPCSerieID"} />
        <ReferenceField
          label={"common.fields.event"}
          source={"eventID"}
          reference={"event"}
          link={"show"}
        >
          <TextField source={"name"} />
        </ReferenceField>
        <ReferenceField
          label={"common.fields.packageType"}
          source={"packageTypeID"}
          reference={"packagetype"}
          link={"show"}
        >
          <TextField source={"name"} />
        </ReferenceField>
        <TextField label={"common.fields.productId"} source={"productID"} />
        <TextField label={"common.fields.topupValue"} source={"topupValue"} />
        <TextField label={"common.fields.feederID"} source={"feederID"} />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default PackageRelationsList;
