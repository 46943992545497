import moment from "moment";
import {
  Button,
  Datagrid,
  ExportButton,
  FilterButton,
  List,
  NumberInput,
  TextField,
  TopToolbar,
  useRecordContext,
  useRefresh,
  useTranslate,
} from "react-admin";
import "./code.css"; // Import the CSS file

import AutorenewIcon from "@mui/icons-material/Autorenew";
import UpdateDisabledIcon from "@mui/icons-material/UpdateDisabled";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

// const now = new Date();
// const yesterdayBegin = new Date().getTime() -(24*60*60*1000);
// const todayNoon = new Date().getTime() + (60*1000);
// const [datVal, onChange] = useState([yesterdayBegin, todayNoon]);

const DateFilter = (props: any) => {
  return [
    <NumberInput
      source="CardU.KarteID_Payment"
      label="resources.cardTX.cardID"
      alwaysOn
      step={0}
    />,
    // <DateTimeInput source="end" alwaysOn />,
  ];
};

const Empty = () => (
  <div>
    <ListActions></ListActions>
    <a> No data</a>
  </div>
);

const TimeField = (label: any) => {
  const record = useRecordContext();
  return (
    <Tooltip title={moment(record.TrxTimestamp).toString()}>
      <a>{moment(record.TrxTimestamp).fromNow()}</a>
    </Tooltip>
  );
};
const ListActions = () => {
  const refresh = useRefresh();
  const [liveReload, changeLiveReload] = useState(0);

  useEffect(() => {
    return () => clearInterval(liveReload);
  });

  return (
    <TopToolbar>
      <FilterButton filters={DateFilter("")} />

      <ExportButton />
      {/* Add your custom actions */}
      <Button
        onClick={() => {
          if (!liveReload) {
            const interval: number = +setInterval(() => refresh(), 1000);
            changeLiveReload(interval);
          } else {
            clearInterval(liveReload);
            changeLiveReload(0);
          }
        }}
        label={liveReload ? "Stop Live Reload" : "Start Live Reload"}
      >
        {liveReload ? <UpdateDisabledIcon /> : <AutorenewIcon />}
      </Button>
    </TopToolbar>
  );
};

const CardTXList = ({ ...props }) => {
  const translate = useTranslate();
  return (
    <List
      title={translate(`resources.cardTX.name`, { smart_count: 2 })}
      {...props}
      filters={DateFilter("")}
      actions={ListActions()}
      empty={<Empty />}
      filterDefaultValues={{}}
      sort={{ field: "Betrag", order: "ASC" }}
    >
      <Datagrid
        bulkActionButtons={false}
        isRowExpandable={(row) => {
          return true;
        }}
        //   expand={<LogDetail></LogDetail>}
      >
        {/* <TextField label="common.reports.event" source="action" /> */}

        <TextField
          label="resources.cardTX.transactionID"
          source="idKartenTransaktion"
        />
        <TextField label="resources.cardTX.cardUID" source="CardUID" />
        <TextField
          label="resources.cardTX.cardID"
          source="CardU.KarteID_Payment"
          sortable={false}
        />
        <TimeField label="resources.cardTX.time" source="TrxTimestamp" />
        <TextField label="resources.cardTX.GuthabenAlt" source="GuthabenAlt" />
        <TextField label="resources.cardTX.Betrag" source="Betrag" />
        <TextField label="resources.cardTX.GuthabenNeu" source="GuthabenNeu" />
      </Datagrid>
    </List>
  );
};

export default CardTXList;
