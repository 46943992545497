import * as React from "react";
import PackageRelationList from './PackageRelationList';
import PackageRelationCreate from './PackageRelationCreate';
import PackageRelationEdit from './PackageRelationEdit';
import PackageRelationShow from './PackageRelationShow';

export default {
  list: PackageRelationList,
	show: PackageRelationShow,
  create: PackageRelationCreate,
  edit: PackageRelationEdit,
};
