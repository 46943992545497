import * as React from "react";
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import SmartmatList from './SmartmatList';
import SmartmatCreate from './SmartmatCreate';
import SmartmatShow from './SmartmatShow';
import SmartmatEdit from './SmartmatEdit';

export default {
  list: SmartmatList,
  create: SmartmatCreate,
  edit: SmartmatEdit,
  show: SmartmatShow,
  icon: AutoAwesomeMotionIcon,
};
