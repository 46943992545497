/* eslint react/jsx-key: off */
import * as React from "react";
// @ts-ignore
import {
  Show,
  TextField,
  DateField,
  usePermissions,
  Confirm,
  useGetOne,
  useDelete,
  useNotify,
  useRedirect,
  useTranslate,
  ReferenceField,
  SelectField,
  TabbedShowLayout,
  Tab,
  Datagrid,
  ReferenceManyField,
} from "react-admin";
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { ProgressBar } from "../helpers/ProgressBar";
import { useState } from "react";
import { formatDate } from "../dataProvider";
import {
  EventTimeStatusField,
  CreatedAndUpdatedField,
  IsActiveField,
  CapacityField,
} from "../components/CustomFields";
import { paymentProviders } from "../constants/paymentProviders";
import { binTypes } from "../constants/binTypes";

const CaptionItem = (props: any) => {
  return (
    <Typography
      variant="caption"
      display="block"
      gutterBottom
      sx={{ color: "#717171" }}
    >
      {props.children}
    </Typography>
  );
};

const DateItem = (props: any) => {
  return (
    <Typography
      variant="body1"
      display="block"
      gutterBottom
      sx={{ marginTop: "0px !important" }}
    >
      {formatDate(props.children)}
    </Typography>
  );
};

export const EventShow = (props: any) => {
  const { permissions } = usePermissions();
  const { id } = useParams();
  const [isGetOneError, setIsGetOneError] = useState(false);

  const { data } = useGetOne(
    "event",
    { id },
    {
      onError: (err) => {
        setIsGetOneError(true);
        console.log(err);
      },
    }
  );

  const [deleteOne, { isLoading, error }] = useDelete("event", {
    id,
    previousData: data,
  });
  /// confirm window functionality
  const [open, setOpen] = useState(false);
  const handleDelete = () => setOpen(true);
  const handleDialogClose = () => {
    setOpen(false);
  };
  const redirect = useRedirect();
  const notify = useNotify();
  //deleting event
  const handleConfirm = () => {
    setOpen(false);
    deleteOne();
    if (!error) {
      notify(translate("ra.notification.deleted"), { type: "success" });
      redirect("/event");
    }
    if (error) {
      notify(translate("resources.events.messages.notDeleted"), {
        type: "warning",
      });
    }
  };
  ///end confirm window functionality
  const translate = useTranslate();

  return !data ? (
    <ProgressBar />
  ) : (
    <Show {...props} title={data.name}>
      <TabbedShowLayout>
        <Tab label="common.fieldGroups.summary">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{translate("common.fields.id")}</TableCell>
                <TableCell>
                  <TextField label={"common.fields.id"} source={"id"} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{translate("common.fields.name")}</TableCell>
                <TableCell>
                  <TextField label={"common.fields.name"} source={"name"} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {translate("resources.events.fields.live")}
                </TableCell>
                <TableCell>
                  <EventTimeStatusField
                    label="resources.events.fields.live"
                    source="eventTimeStatus"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {translate("resources.events.labels.duration")}
                </TableCell>
                <TableCell>
                  {translate("common.words.from")}{" "}
                  <DateField label="" source="startTime" showTime={true} />{" "}
                  {translate("common.words.to")}{" "}
                  <DateField label="" source="endTime" showTime={true} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  {translate("resources.events.fields.paymentMetadata")}
                </TableCell>
                <TableCell>
                  <TextField
                    label="resources.events.fields.paymentMetadata"
                    source="organizerPaymentMetadata.cardID"
                    fullWidth={true}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {translate("common.fields.paymentProvider")}
                </TableCell>
                <TableCell>
                  <SelectField
                    source="organizerPaymentProvider"
                    choices={paymentProviders.choices}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {translate("resources.events.fields.paymentEventId")}
                </TableCell>
                <TableCell>
                  <TextField source="remoteEventID" fullWidth={true} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Tab>
        <Tab label="resources.events.tabs.smartbins">
          <ReferenceManyField
            label={false}
            target="eventID"
            reference="smartbin"
          >
            <Datagrid bulkActionButtons={false}>
              <ReferenceField
                label={"common.fields.id"}
                source={"SID"}
                reference={"smartbin"}
                link={"show"}
              >
                <TextField source="SID" />
              </ReferenceField>
              <TextField source={"name"} label={"common.fields.name"} />
              <SelectField
                label={"common.fields.type"}
                source={"smartbinTypeId"}
                choices={binTypes.choices}
              />
              <IsActiveField label={"common.fields.online"} source={"active"} />
              <CapacityField
                label="resources.smartbins.fields.capacity"
                source="usedCapacity"
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="resources.events.tabs.smartmats">
          <ReferenceManyField
            label={false}
            target="eventID"
            reference="smartmat"
          >
            <Datagrid bulkActionButtons={false}>
              <ReferenceField
                label={"common.fields.id"}
                source={"deviceID"}
                reference={"smartmat"}
                link={"show"}
              >
                <TextField source="deviceID" />
              </ReferenceField>
              <TextField source={"name"} label={"common.fields.name"} />
              <TextField
                label={"common.fields.remotePosID"}
                source={"remotePosID"}
              />
              <IsActiveField label={"common.fields.online"} source={"active"} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="resources.events.tabs.packagerelations">
          <ReferenceManyField
            label={false}
            target="eventID"
            reference="packagerelation"
          >
            <Datagrid bulkActionButtons={false}>
              <ReferenceField
                label={"common.fields.id"}
                source={"packageRelationID"}
                reference={"packagerelation"}
                link={"show"}
              >
                <TextField source={"packageRelationID"} />
              </ReferenceField>
              <TextField
                label={"common.fields.epcSerieId"}
                source={"EPCSerieID"}
              />
              <ReferenceField
                label={"common.fields.packageType"}
                source={"packageTypeID"}
                reference={"packagetype"}
                link={"show"}
              >
                <TextField source={"name"} />
              </ReferenceField>
              <TextField
                label={"common.fields.productId"}
                source={"productID"}
              />
              <TextField
                label={"common.fields.topupValue"}
                source={"topupValue"}
              />
              <TextField label={"common.fields.feederID"} source={"feederID"} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>

      <Confirm
        isOpen={open}
        title={translate("common.button.delete")}
        content={translate("ra.message.are_you_sure")}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
      <CreatedAndUpdatedField />
    </Show>
  );
};

export default EventShow;
