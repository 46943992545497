import * as React from "react";
import {
  Datagrid,
  List,
  TextField,
  ShowButton,
  ReferenceField,
} from "react-admin";
import { IsActiveField, CapacityField } from "../components/CustomFields";

const SmartbinList = () => {
  return (
    <List>
      <Datagrid>
        <TextField label="common.fields.name" source="name" />
        <TextField label="common.fields.id" source="SID" />
        <IsActiveField label={"common.fields.online"} source={"active"} />
        <CapacityField
          label="resources.smartbins.fields.capacity"
          source="usedCapacity"
        />
        <ReferenceField
          label={"common.fields.event"}
          source={"eventID"}
          reference={"event"}
        >
          <TextField source={"name"} />
        </ReferenceField>
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default SmartbinList;
