import * as React from "react";
import {
  Datagrid,
  List,
  TextField,
  ShowButton,
  ReferenceField,
} from "react-admin";
import { IsActiveField } from "../components/CustomFields";

const SmartmatList = () => {
  return (
    <List>
      <Datagrid>
        <TextField label={"common.fields.name"} source={"name"} />
        <TextField label={"common.fields.id"} source="deviceID" />
        <TextField label={"common.fields.remotePosID"} source={"remotePosID"} />
        <IsActiveField label={"common.fields.online"} source={"active"} />
        <ReferenceField
          label={"common.fields.event"}
          source={"eventID"}
          reference={"event"}
          link={"show"}
        >
          <TextField source={"name"} />
        </ReferenceField>
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default SmartmatList;
