/* eslint react/jsx-key: off */
import Grid from "@mui/material/Grid";
import { maxLength, minLength } from "ra-core";
import {
	Edit,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	required
} from "react-admin";
import { CancelButtonToolbar } from "../components/CustomToolbars";
import { feederTypes } from "../constants/feederTypes";

const validateName = [required(), minLength(1), maxLength(40)];

const PackageRelationEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm redirect="list" toolbar={<CancelButtonToolbar />}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ReferenceInput source={"eventID"} reference={"event"}>
              <SelectInput
                label={"common.fields.event"}
                optionText={"name"}
                required
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput source={"packageTypeID"} reference={"packagetype"}>
              <SelectInput
                label={"common.fields.packageType"}
                optionText={"name"}
                required
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput source={"EPCSerieID"} reference={"epcserie"}>
              <SelectInput
                label={"common.fields.epcSerieId"}
                optionText={"id"}
                required
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput source={"productID"} reference={"product"}>
              <SelectInput
                label={"common.fields.productId"}
                optionText={"name"}
                required
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label={"common.fields.feederID"}
              source={"feederID"}
              type={"text"}
              required
              defaultValue={"A"}
              fullWidth
              choices={feederTypes.choices}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default PackageRelationEdit;
