/* eslint react/jsx-key: off */
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  useTranslate,
} from "react-admin";
import Grid from "@mui/material/Grid";
import { binTypes } from "../constants/binTypes";
import { CancelButtonToolbar } from "../components/CustomToolbars";

const SmartbinEdit = () => {
  const translate = useTranslate();
  const nameTip = translate("common.tips.devicename");
  const idTip = translate("resources.smartbins.tips.id");
  const binTip = translate("resources.smartbins.tips.bintype");
  const howtoTip = translate("resources.smartbins.tips.howto");

  return (
    <Edit>
      <SimpleForm redirect="show" toolbar={<CancelButtonToolbar />}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput
              label={"common.fields.id"}
              source={"SID"}
              title={idTip}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label={"common.fields.type"}
              source={"smartbinTypeId"}
              type={"number"}
              required
              defaultValue={"1"}
              fullWidth
              title={binTip}
              choices={binTypes.choices}
            />
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput source={"eventID"} reference={"event"}>
              <SelectInput
                label={"common.fields.event"}
                optionText={"name"}
                required
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              label={"common.fields.name"}
              source={"name"}
              title={nameTip}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default SmartbinEdit;
