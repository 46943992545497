import * as React from "react";
import {
  Create,
  TextInput,
  useNotify,
  SimpleForm,
  useTranslate,
  SelectInput,
  ReferenceInput,
} from "react-admin";
import Grid from "@mui/material/Grid";
import { paymentProviders } from "../constants/paymentProviders";
import { CancelButtonToolbar } from "../components/CustomToolbars";

const CashierCreate = () => {
  const translate = useTranslate();

  const notify = useNotify();

  const onError = (error: any) => {
    notify(translate("resources.cashier.messages.cantCreate"));
  };

  return (
    <Create redirect="show" mutationOptions={{ onError }} resource="cashier">
      <SimpleForm toolbar={<CancelButtonToolbar />}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput
              label={"common.fields.name"}
              source={"name"}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <ReferenceInput source={"id"} reference={"event"}>
              <SelectInput
                label={"common.fields.event"}
                optionText={"name"}
                fullWidth
              />
            </ReferenceInput>
          </Grid>

          <Grid item xs={6}>
            <SelectInput
              label={"common.fields.paymentProvider"}
              source={"paymentProviderID"}
              type={"number"}
              required
              fullWidth
              defaultValue={1}
              choices={paymentProviders.choices}
            />
          </Grid>

          <Grid item xs={6}>
            <TextInput
              source={"cashierRemoteID"}
              label={"resources.cashier.fields.cashierRemoteID"}
              required
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default CashierCreate;
