import * as React from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { Route } from "react-router";

import authProvider from "./authProvider";
import { Login, Layout } from "./layout";
import englishMessages from "./i18n/en";
import { lightTheme } from "./layout/themes";

import simpleRestProvider from "./dataProvider";
import Configuration from "./configuration/Configuration";
import users from "./users/index";
import event from "./events/index";
import smartbin from "./smartbins/index";
import packagetype from "./packageTypes/index";
import packagerelation from "./packageRelations/index";
import cashier from "./cashier/index";
import smartmat from "./smartmats/index";
import cardTX from "./cardTX/index";
import Packages from "./packages/index";
import EPCSeries from "./EPCSeries";
const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "nl") {
    return import("./i18n/nl").then((messages) => messages.default);
  }
  if (locale === "fr") {
    return import("./i18n/fr").then((messages) => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, "en");

const App = () => {
  return (
    <Admin
      title=""
      dataProvider={simpleRestProvider(
        process.env.REACT_APP_DATA_PROVIDER || ""
      )}
      authProvider={authProvider}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
      disableTelemetry
      theme={lightTheme}
    >
      <CustomRoutes>
        <Route path="/configuration" element={<Configuration />} />
      </CustomRoutes>
      <Resource name="event" {...event} />
      <Resource name="packagetype" {...packagetype} />
      <Resource name="packagerelation" {...packagerelation} />
      <Resource name="smartbin" {...smartbin} />
      <Resource name="smartmat" {...smartmat} />
      <Resource name="cashier" {...cashier} />
      <Resource name="users" {...users} />
      <Resource name="cardTX" {...cardTX} />
      <Resource name="package" {...Packages} />
      <Resource name="epcserie" {...EPCSeries} />
    </Admin>
  );
};

export default App;
