import * as React from "react";
import {
  Datagrid,
  List,
  TextField,
  ShowButton,
  useRecordContext,
  ReferenceField,
  useTranslate,
} from "react-admin";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckIcon from "@mui/icons-material/Check";

const IsAssignedField = (label: any) => {
  const record = useRecordContext();
  if (!record.eventID) {
    return <WarningAmberIcon color="warning" />;
  }
  return <CheckIcon color="success" />;
};

const CashierList = () => {
  const translate = useTranslate();
  return (
    <List>
      <Datagrid>
        <TextField label={"common.fields.name"} source={"name"} />
        <TextField label={"common.fields.id"} source={"id"} />
        <ReferenceField
          label={"common.fields.paymentProvider"}
          source={"paymentProviderID"}
          reference="paymentProvider"
          link={false}
        >
          <TextField source={"name"} />
        </ReferenceField>
        <ReferenceField
          label={"common.fields.event"}
          source={"eventID"}
          reference={"event"}
        >
          <TextField source={"name"} />
        </ReferenceField>
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default CashierList;
