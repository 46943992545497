/* eslint react/jsx-key: off */
import {
	Table,
	TableBody,
	TableCell,
	TableRow
} from "@mui/material";
import {
	Button,
	Create,
	FormTab,
	ReferenceInput,
	SaveButton,
	SelectInput,
	TabbedForm,
	TextInput,
	Toolbar,
	email,
	regex,
	required,
	useRedirect
} from "react-admin";
import { useFormContext } from "react-hook-form";
import {
	doesPwMatch,
	storePw
} from "../helpers/validators";

const UserCreateToolbar = ({ ...props }) => {
  //const notify = useNotify();
  const { reset } = useFormContext();

  const redirect = useRedirect();
  const handleClick = () => redirect("/users");

  return (
    <Toolbar {...props}>
      <SaveButton label="common.button.create" />
      <Button
        label="common.button.cancel"
        variant="outlined"
        onClick={handleClick}
      />
    </Toolbar>
  );
};

const UserCreate = () => {
  /*const notify = useNotify();
	const redirect = useRedirect();*/

  return (
    <Create redirect="list">
      <TabbedForm>
        <FormTab label="common.fieldGroups.identity" path="">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <TextInput
                    label="resources.users.fields.firstname"
                    source="firstName"
                    validate={[required()]}
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    label="resources.users.fields.lastname"
                    source="lastName"
                    validate={[required()]}
                    fullWidth
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <TextInput
                    type="email"
                    source="email"
                    label="resources.users.fields.email"
                    validate={[
                      required(),
                      email("resources.general.messages.notemail"),
                    ]}
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    source="phone"
                    label="resources.users.fields.phone"
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <ReferenceInput
                    label="resources.users.fields.role"
                    source="roleId"
                    reference="groups"
                    validate={required()}
                  >
                    <SelectInput optionText={"name"} />
                  </ReferenceInput>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <TextInput
                    source="password"
                    type="password"
                    label="resources.users.fields.password"
                    value=""
                    validate={[
                      storePw,
                      required(),
                      regex(
                        /(?=.*\d)(?=.*[a-zA-Z])(?=.*[@#$%^&*->,<!]).{9,}/,
                        "resources.users.errors.password_unsecure"
                      ),
                    ]}
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    source="repeatPwd"
                    label="resources.users.fields.confirm_password"
                    type="password"
                    value=""
                    // @ts-ignore
                    validate={[
                      required("resources.users.errors.password_required"),
                      doesPwMatch,
                    ]}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </FormTab>
        <FormTab label="common.fieldGroups.company" path="company">
          <TextInput
            source="company.name"
            label="resources.users.fields.company"
            fullWidth={true}
          />
          <TextInput
            source="company.address"
            label="resources.users.fields.address"
            fullWidth={true}
          />
          <TextInput
            source="company.country"
            label="resources.users.fields.country"
          />
          <TextInput
            source="company.www"
            label="resources.users.fields.www"
            type="url"
            fullWidth={true}
          />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default UserCreate;
