/* eslint react/jsx-key: off */
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { maxLength, minLength } from "ra-core";
import {
	BooleanInput,
	Edit,
	FormTab,
	ReferenceInput,
	SelectInput,
	TabbedForm,
	TextInput,
	email,
	required,
	usePermissions
} from "react-admin";
import { isMatchWarn, storePw } from "../helpers/validators";
import { UserTitle } from "./UserTitle";

/**
 * Custom Toolbar for the Edit form
 *
 * Save with undo, but delete with confirm
 */

const validateName = [required(), minLength(2), maxLength(15)];

const UserEditForm = () => {
  const { permissions } = usePermissions();
  return (
    <TabbedForm>
      <FormTab label="common.fieldGroups.identity">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <TextInput disabled source={"id"} />
              </TableCell>
              <TableCell>
                {permissions === "admin" && (
                  <BooleanInput
                    label="resources.users.fields.active"
                    source={"active"}
                  />
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2}>
                <TextInput
                  label={"common.fields.name"}
                  source="displayName"
                  validate={validateName}
                  fullWidth
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <TextInput
                  label="resources.users.fields.firstname"
                  source="firstName"
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <TextInput
                  label="resources.users.fields.lastname"
                  source="lastName"
                  fullWidth
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <TextInput
                  type="email"
                  source="email"
                  label="resources.users.fields.email"
                  validate={[
                    required(),
                    email("resources.general.messages.notemail"),
                  ]}
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <TextInput
                  source="phone"
                  label="resources.users.fields.phone"
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <ReferenceInput
                  label="resources.users.fields.role"
                  source="roleId"
                  reference="groups"
                  validate={[required("resources.users.messages.fillrole")]}
                >
                  <SelectInput optionText={"name"} />
                </ReferenceInput>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <TextInput
                  source="password"
                  type="password"
                  label="resources.users.fields.password"
                  value=""
                  validate={storePw}
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <TextInput
                  source="repeatPwd"
                  label="resources.users.fields.confirm_password"
                  type="password"
                  value=""
                  // @ts-ignore
                  validate={isMatchWarn(
                    "resources.users.errors.password_required"
                  )}
                  fullWidth
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </FormTab>
      <FormTab label="common.fieldGroups.company" path="company">
        <TextInput label="Company" source="company.name" />
        <TextInput
          label="resources.users.fields.address"
          source="company.address"
          fullWidth={true}
        />
        <TextInput
          label="resources.users.fields.country"
          source="company.country"
        />
        <TextInput
          label="resources.users.fields.www"
          source="company.www"
          // format={v => (v ? v : '')}
          type="url"
        />
      </FormTab>
    </TabbedForm>
  );
};
const UserEdit = () => {
  return (
    <Edit title={<UserTitle />} actions={false}>
      <UserEditForm />
    </Edit>
  );
};

export default UserEdit;
