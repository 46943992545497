import moment from "moment";
import {
	Button,
	Datagrid,
	ExportButton,
	List,
	ReferenceField,
	Show,
	SimpleShowLayout,
	TextField,
	TextInput,
	TopToolbar,
	useRecordContext,
	useRefresh,
	useResourceContext,
	useTranslate
} from "react-admin";
import "./code.css"; // Import the CSS file

import AutorenewIcon from "@mui/icons-material/Autorenew";
import UpdateDisabledIcon from "@mui/icons-material/UpdateDisabled";
import { Card, CardContent, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

// const now = new Date();
// const yesterdayBegin = new Date().getTime() -(24*60*60*1000);
// const todayNoon = new Date().getTime() + (60*1000);
// const [datVal, onChange] = useState([yesterdayBegin, todayNoon]);

const DateFilter = (props: any) => {
  return [
    <TextInput source="EPC" label="EPC" alwaysOn />,
    // <DateTimeInput source="end" alwaysOn />,
  ];
};

const Empty = () => (
  <div>
    <ListActions></ListActions>
    <a> No data</a>
  </div>
);

const TimeField = (attr: any) => {
  const record = useRecordContext();
  return (
    <Tooltip title={moment(record[attr.source]).toString()}>
      <a>{moment(record[attr.source]).calendar()}</a>
    </Tooltip>
  );
};
const ListActions = () => {
  const refresh = useRefresh();
  const [liveReload, changeLiveReload] = useState(0);

  useEffect(() => {
    return () => clearInterval(liveReload);
  });

  return (
    <TopToolbar>
      <ExportButton />
      {/* Add your custom actions */}
      <Button
        onClick={() => {
          if (!liveReload) {
            const interval: number = +setInterval(() => refresh(), 1000);
            changeLiveReload(interval);
          } else {
            clearInterval(liveReload);
            changeLiveReload(0);
          }
        }}
        label={liveReload ? "Stop Live Reload" : "Start Live Reload"}
      >
        {liveReload ? <UpdateDisabledIcon /> : <AutorenewIcon />}
      </Button>
    </TopToolbar>
  );
};

const LogDetail = ({ ...props }) => {
  return (
    <Show {...props}>
      <div style={{ width: "min-content" }}>
        <SimpleShowLayout>
          <TextField source={"id"} />

          {props.record.SID ? (
            <ReferenceField
              source={"SID"}
              reference="smartbin"
              label={"SID (" + props.record.SID + ")"}
            >
              {" "}
              <TextField source={"name"} />{" "}
            </ReferenceField>
          ) : null}
          {props.record.deviceID ? (
            <ReferenceField
              source="deviceID"
              reference="smartmat"
              label={"deviceID (" + props.record.deviceID + ")"}
            >
              {" "}
              <TextField source={"name"} />{" "}
            </ReferenceField>
          ) : null}
          {props.record.eventID ? (
            <ReferenceField
              source={"eventID"}
              reference={"event"}
              label={"eventID (" + props.record.eventID + ")"}
            >
              {" "}
              <TextField source={"name"} />{" "}
            </ReferenceField>
          ) : null}
          {props.record.activatorCashierID ? (
            <ReferenceField
              source="cashierID"
              reference={"event"}
              label={
                "activatorCashierID (" + props.record.activatorCashierID + ")"
              }
            >
              {" "}
              <TextField source={"name"} />{" "}
            </ReferenceField>
          ) : null}
          <Card>
            <CardContent className="code">
              {JSON.stringify(props.record)}
            </CardContent>
          </Card>
        </SimpleShowLayout>
      </div>
    </Show>
  );
};

const Packages = ({ ...props }) => {
  const translate = useTranslate();
  return (
    <List
      title={translate(`resources.packages.name`, { smart_count: 2 })}
      {...props}
      filters={DateFilter("")}
      actions={ListActions()}
      empty={<Empty />}
      filterDefaultValues={{}}
    >
      <Datagrid
        bulkActionButtons={false}
        isRowExpandable={(row) => {
          return true;
        }}
      >
        {/* <TextField label="common.reports.event" source="action" /> */}

        <TextField label="resources.packages.EPC" source="EPC" />
        <TextField label="resources.packages.status" source="status" />
        <TimeField
          label="resources.packages.activatedAt"
          source="activatedAt"
        />
        <TimeField label="resources.packages.returnedAt" source="returnedAt" />
        {/* <TimeField label="createdAt"  source="createdAt"/>
        <TimeField label="updatedAt"  source="updatedAt"/> */}
        <TextField
          label="resources.packages.priceAmount"
          source="paymentMetadata.priceAmount"
        />
        <TextField
          label="resources.packages.cardID"
          source="paymentMetadata.cardID"
        />
        <ReferenceField
          label="resources.packages.returnSmartbin"
          reference="smartbin"
					link="show"
          source="SID"
        >
          <TextField source={"name"} />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default Packages;
