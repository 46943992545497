/* eslint react/jsx-key: off */
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  useTranslate,
} from "react-admin";
import Grid from "@mui/material/Grid";
import { CancelButtonToolbar } from "../components/CustomToolbars";

const SmartbinEdit = () => {
  const translate = useTranslate();
  const nameTip = translate("common.tips.devicename");
  const idTip = translate("resources.smartbins.tips.id");

  return (
    <Edit>
      <SimpleForm redirect="show" toolbar={<CancelButtonToolbar />}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput
              label={"common.fields.id"}
              source={"EPCSerieID"}
              title={idTip}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              label="resources.epcserie.fields.wildcard"
              source="EPCWildcard"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput
              source={"paymentProviderID"}
              reference={"paymentProvider"}
            >
              <SelectInput
                label={"common.fields.paymentProvider"}
                optionText={"name"}
                required
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              label={"common.fields.name"}
              source={"name"}
              title={nameTip}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default SmartbinEdit;
