import { Show, TextField, SimpleShowLayout, useGetOne } from "react-admin";
import { useParams } from "react-router-dom";
import { CreatedAndUpdatedField } from "../components/CustomFields";
import { DeleteForAdminToolbar } from "../components/CustomToolbars";

const PackageTypeShow = () => {
  const { id } = useParams();
  const { data } = useGetOne("packagetype", { id });

  return (
    <Show actions={<DeleteForAdminToolbar />} title={data.name}>
      <SimpleShowLayout>
        <TextField label={"common.fields.name"} source={"name"} />
        <TextField label={"common.fields.id"} source={"packageTypeID"} />
      </SimpleShowLayout>
      <CreatedAndUpdatedField />
    </Show>
  );
};

export default PackageTypeShow;
