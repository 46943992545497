import {
	Cable,
	DatasetLinked,
	Inventory,
	SupportAgent,
	ViewCozySharp,
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import { useState } from "react";
import PackageTypeIcon from "../packageTypes/PackageTypeIcon";

import {
	MenuItemLink,
	MenuProps,
	useSidebarState,
	useTranslate
} from "react-admin";

import event from "../events";
import smartbin from "../smartbins";
import smartmat from "../smartmats";
import users from "../users";
//import cashier from '../cashier';
import cardTXList from "../cardTX";
import Packages from "../packages";
import SubMenu from "./SubMenu";
type MenuNameDevices = "menuDevices";
type MenuNamePackages = "menuPackages";
type MenuNameSupport = "menuSupport";

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuDevices: true,
    menuPackages: true,
    menuSupport: true,
  });
  const translate = useTranslate();
  const [open] = useSidebarState();

  const handleToggleDevices = (menu: MenuNameDevices) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  const handleTogglePackages = (menu: MenuNamePackages) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  const handleToggleSupport = (menu: MenuNameSupport) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  //            <DashboardMenuItem />
  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <MenuItemLink
        to="/event"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.events.name`, {
          smart_count: 2,
        })}
        leftIcon={<event.icon />}
        dense={dense}
      />
      <SubMenu
        handleToggle={() => handleTogglePackages("menuPackages")}
        isOpen={state.menuPackages}
        name="pos.menu.packages"
        icon={<PackageTypeIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/packagetype"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.packageTypes.name`, {
            smart_count: 2,
          })}
          leftIcon={<ViewCozySharp />}
          dense={dense}
        />
        <MenuItemLink
          to="/packagerelation"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.packageRelations.name`, {
            smart_count: 2,
          })}
          leftIcon={<DatasetLinked />}
          dense={dense}
        />
        <MenuItemLink
          to="/epcserie"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.epcserie.name`, {
            smart_count: 2,
          })}
          leftIcon={<Inventory />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggleDevices("menuDevices")}
        isOpen={state.menuDevices}
        name="pos.menu.devices"
        icon={<Cable />}
        dense={dense}
      >
        <MenuItemLink
          to="/smartbin"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.smartbins.name`, {
            smart_count: 2,
          })}
          leftIcon={<smartbin.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/smartmat"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.smartmats.name`, {
            smart_count: 2,
          })}
          leftIcon={<smartmat.icon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggleSupport("menuSupport")}
        isOpen={state.menuSupport}
        name="pos.menu.support"
        icon={<SupportAgent />}
        dense={dense}
      >
        <MenuItemLink
          to="/cardTX"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.cardTX.name`, {
            smart_count: 2,
          })}
          leftIcon={<cardTXList.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/package"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.packages.name`, {
            smart_count: 2,
          })}
          leftIcon={<Packages.icon />}
          dense={dense}
        />
      </SubMenu>
      <MenuItemLink
        to="/users"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.users.name`, {
          smart_count: 2,
        })}
        leftIcon={<users.icon />}
        dense={dense}
      />
    </Box>
  );
};

export default Menu;
