import * as React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useRecordContext, useTranslate } from "react-admin";

import { User } from "../types";

const UserShow = () => {
  const record = useRecordContext<User>();
  //{translate('resources.user.fields.address')}
  if (!record) return null;
  return (
    <Card sx={{ marginLeft: 51 }}>
      <CardContent>
        <UserDetails />
      </CardContent>
    </Card>
  );
};

function extractAddress(data: any) {
  let address = "";
  if (data.location == undefined || data.location == null) {
    address = "";
  } else {
    if (data.location.address !== undefined) address = data.location.address;
    if (data.location.zip !== undefined) address += ", " + data.location.zip;
    if (data.location.place !== undefined)
      address += ", " + data.location.place;
    if (data.location.country !== null && data.location.country.name !== null)
      if (
        data.location.country !== undefined &&
        data.location.country.name !== undefined
      )
        address += ", " + data.location.country.name;
  }
  return address;
}

function extractCompany(data: any) {
  let company = "";
  if (data.company == undefined || data.company == null) {
    company = "";
  } else {
    if (data.company.name !== undefined) company = data.company.name;
    if (data.company.www !== undefined) company += ", " + data.company.www;
    company += ", " + extractAddress(data.company);
  }
  return company;
}

const UserDetails = () => {
  const record = useRecordContext<User>();
  const translate = useTranslate();
  const addressData = extractAddress(record);
  const companyData = extractCompany(record);

  return record ? (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography>
          <b>{translate("resources.users.fields.address")}:</b>
          <br />
          {addressData}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          <b>{translate("resources.users.fields.company")}:</b>
          <br />
          {companyData}
        </Typography>
      </Grid>
    </Grid>
  ) : null;
};

export default UserShow;
