import * as React from "react";
import { SVGProps } from "react";
import { useTheme } from "@mui/material/styles";

const Logo = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg
      version="1.1"
      width={235}
      height={25}
      viewBox="0 0 2186.5 312.9"
      {...props}
    >
      <path
        d="M227.1,70.1c-37.1,0-65.2,28.6-65.2,63.5c0,35.4,28.1,64,65.2,64c37.3,0,65-28.6,65-64C292,98.7,264.4,70.1,227.1,70.1z
							M227.1,178c-24.4,0-44.1-19.7-44.1-44.1c0-24.4,19.7-44.1,44.1-44.1c0,0,0,0,0,0c24.2-0.2,43.9,19.2,44.1,43.4c0,0.2,0,0.3,0,0.5
							C271.2,157.9,252.3,178,227.1,178z"
      />
      <path
        d="M375,70.1c-37.1,0-65.2,28.6-65.2,63.5c0,35.4,28.1,64,65.2,64c37.3,0,65-28.6,65-64C439.9,98.7,412.3,70.1,375,70.1z
							M375,178c-24.4,0-44.1-19.7-44.1-44.1c0-24.4,19.7-44.1,44.1-44.1c0,0,0,0,0,0c24.2-0.2,43.9,19.2,44.1,43.4c0,0.2,0,0.3,0,0.5
							C419.1,157.9,400.2,178,375,178z"
      />
      <path
        d="M569.2,100.4c-7.8-17.2-26.4-30.3-49.4-30.3c-35.2,0-62,28.6-62,63.5c0,35.4,26.9,64,62,64c23,0,41.7-13.1,49.5-30.3l1,27.9
							H590V13.4h-20.9V100.4z M522.9,178c-24.4,0-44.1-19.7-44.1-44.1c0-24.4,19.7-44.1,44.1-44.1c0,0,0,0,0,0
							c24.2-0.2,43.9,19.2,44.1,43.4c0,0.2,0,0.3,0,0.5C567,157.9,548.1,178,522.9,178z"
      />
      <rect x="617.7" y="13.4" width="20.9" height="181.8" />
      <path
        d="M725.3,70.1c-36.3,0-61.3,28.6-61.3,63c0,37.1,26.2,64.5,63.3,64.5c32.2,0,51.2-21.3,56-38.1l-18.4-2.4
							c-5.3,10.9-17.5,21.8-37.6,21.8c-25.2,0-41.7-16.3-42.4-38.5h101.8v-5.3C786.7,100.4,762.9,70.1,725.3,70.1z M684.9,125.4
							c0.7-20.1,17.5-36.6,40.5-36.6c24,0,39.3,17.5,40.5,36.6L684.9,125.4z"
      />
      <path
        d="M864.7,126.6c-24.5-9.5-36.8-12.6-36.8-23.8c0-9.9,11.4-15.3,21.3-15.3c11.2,0,21.8,6.1,23.3,17.2h20.6
							c-1.2-19.9-18.4-34.7-44.4-34.7c-21.1,0-41.5,12.1-41.5,32.7c0,19.4,16.5,28.6,34.4,34.7c25.7,9.7,38.3,12.6,38.3,26.4
							c0,9.7-10.4,16-25.2,16c-17.2,0-27.7-7.5-30.8-18.6h-20.9c2.9,23.5,23,36.3,51.1,36.3c26.9,0,46.5-12.8,46.5-33.2
							C900.9,146,887.7,135.1,864.7,126.6z"
      />
      <path
        d="M977.5,126.6c-24.5-9.5-36.8-12.6-36.8-23.8c0-9.9,11.4-15.3,21.3-15.3c11.2,0,21.8,6.1,23.3,17.2h20.6
							c-1.2-19.9-18.4-34.7-44.4-34.7c-21.1,0-41.5,12.1-41.5,32.7c0,19.4,16.5,28.6,34.4,34.7c25.7,9.7,38.3,12.6,38.3,26.4
							c0,9.7-10.4,16-25.2,16c-17.2,0-27.6-7.5-30.8-18.6h-20.9c2.9,23.5,23,36.3,51.2,36.3c26.9,0,46.5-12.8,46.5-33.2
							C1013.6,146,1000.5,135.1,977.5,126.6z"
      />
      <path
        d="M147.9,44.2c-8.8-0.1-16.1,6.9-16.2,15.8c0,0.2,0,0.3,0,0.5c0,9,7.3,16.2,16.2,16.2c8.9,0.1,16.1-7,16.3-15.9
							c0-0.1,0-0.3,0-0.4c0.2-8.8-6.7-16-15.4-16.3C148.4,44.2,148.1,44.2,147.9,44.2z"
      />
      <path
        d="M140.1,131.2c0-34.9-27.6-63.5-64.9-63.5C38,67.7,9.9,96.3,9.9,131.2c-0.1,22.1,11.4,42.7,30.3,54.2
							c-18.8,11.4-30.3,31.7-30.3,53.7c0,35.4,28.1,64,65.2,64c37.3,0,65-28.6,65-64c0.1-21.9-11.3-42.3-30-53.7
							C128.8,173.9,140.2,153.3,140.1,131.2z M119.2,239.1c0,24.2-18.9,44.4-44.1,44.4c-24.4,0.4-44.4-19.1-44.8-43.5
							c-0.4-24.4,19.1-44.4,43.5-44.8c0.4,0,0.9,0,1.3,0c24.2-0.2,43.9,19.2,44.1,43.4C119.2,238.8,119.2,238.9,119.2,239.1z M75.1,175.6
							c-24.4,0.4-44.4-19.1-44.8-43.5c-0.4-24.4,19.1-44.4,43.5-44.8c0.4,0,0.9,0,1.3,0c24.2-0.2,43.9,19.2,44.1,43.4c0,0.2,0,0.3,0,0.5
							C119.2,155.5,100.3,175.6,75.1,175.6L75.1,175.6z"
      />
      <g>
        <path d="M1111.8,141.5v-22.1h67.5v22.1H1111.8z" />
        <path
          d="M1268.7,108c0-29.7,8-53,23.9-69.8c15.9-16.8,36.5-25.2,61.8-25.2c16.5,0,31.4,3.9,44.7,11.8c13.3,7.9,23.4,18.9,30.3,33
								c7,14.1,10.4,30.1,10.4,48c0,18.1-3.7,34.4-11,48.7c-7.3,14.3-17.7,25.2-31.1,32.5c-13.4,7.4-27.9,11-43.5,11
								c-16.8,0-31.9-4.1-45.2-12.2c-13.3-8.1-23.3-19.2-30.2-33.3C1272.1,138.6,1268.7,123.7,1268.7,108z M1293.1,108.4
								c0,21.6,5.8,38.6,17.4,51c11.6,12.4,26.1,18.6,43.6,18.6c17.8,0,32.5-6.3,44-18.8c11.5-12.5,17.3-30.3,17.3-53.3
								c0-14.6-2.5-27.3-7.4-38.1s-12.1-19.3-21.6-25.3c-9.5-6-20.1-9-31.9-9c-16.8,0-31.2,5.8-43.3,17.3
								C1299.1,62.2,1293.1,81.5,1293.1,108.4z"
        />
        <path
          d="M1552.1,147.7l21.6,2.8c-2.4,14.9-8.4,26.5-18.1,35c-9.7,8.4-21.7,12.6-35.8,12.6c-17.7,0-32-5.8-42.8-17.4
								c-10.8-11.6-16.2-28.2-16.2-49.9c0-14,2.3-26.2,7-36.7s11.7-18.4,21.2-23.6s19.8-7.9,30.9-7.9c14.1,0,25.6,3.6,34.5,10.7
								c9,7.1,14.7,17.2,17.2,30.3l-21.4,3.3c-2-8.7-5.6-15.3-10.8-19.7s-11.4-6.6-18.7-6.6c-11.1,0-20.1,4-27,11.9
								c-6.9,7.9-10.4,20.5-10.4,37.7c0,17.4,3.3,30.1,10,38c6.7,7.9,15.4,11.8,26.1,11.8c8.6,0,15.8-2.6,21.6-7.9
								C1546.9,166.8,1550.6,158.7,1552.1,147.7z"
        />
        <path
          d="M1640.5,175.5l3.2,19.4c-6.2,1.3-11.7,2-16.6,2c-8,0-14.2-1.3-18.6-3.8s-7.5-5.8-9.3-9.9c-1.8-4.1-2.7-12.8-2.7-25.9V82.7
								h-16.1V65.6h16.1V33.5l21.9-13.2v45.3h22.1v17.1h-22.1v75.8c0,6.3,0.4,10.3,1.2,12.1c0.8,1.8,2,3.2,3.8,4.3
								c1.7,1.1,4.3,1.6,7.5,1.6C1633.3,176.4,1636.5,176.1,1640.5,175.5z"
        />
        <path
          d="M1653.8,130.4c0-24,6.7-41.8,20-53.3c11.1-9.6,24.7-14.4,40.8-14.4c17.8,0,32.4,5.8,43.7,17.5c11.3,11.7,17,27.8,17,48.4
								c0,16.7-2.5,29.8-7.5,39.4c-5,9.6-12.3,17-21.9,22.3s-20,7.9-31.3,7.9c-18.1,0-32.8-5.8-44-17.5
								C1659.4,169,1653.8,152.3,1653.8,130.4z M1676.4,130.4c0,16.6,3.6,29,10.9,37.3c7.2,8.3,16.4,12.4,27.3,12.4
								c10.9,0,20-4.2,27.2-12.5c7.2-8.3,10.9-21,10.9-38c0-16-3.6-28.2-10.9-36.4c-7.3-8.3-16.3-12.4-27.2-12.4c-11,0-20.1,4.1-27.3,12.3
								C1680,101.4,1676.4,113.8,1676.4,130.4z"
        />
        <path
          d="M1801,244.9V65.6h20v16.8c4.7-6.6,10-11.5,16-14.8c5.9-3.3,13.1-4.9,21.6-4.9c11.1,0,20.8,2.8,29.3,8.5
								c8.5,5.7,14.9,13.7,19.2,24.1c4.3,10.4,6.5,21.7,6.5,34.1c0,13.3-2.4,25.2-7.1,35.8c-4.8,10.6-11.7,18.8-20.8,24.4
								c-9.1,5.7-18.6,8.5-28.6,8.5c-7.3,0-13.9-1.5-19.7-4.6s-10.6-7-14.3-11.7v63.1H1801z M1820.9,131.1c0,16.7,3.4,29,10.1,37
								c6.8,8,14.9,12,24.5,12c9.8,0,18.1-4.1,25.1-12.4c7-8.3,10.4-21.1,10.4-38.4c0-16.5-3.4-28.9-10.2-37.1
								c-6.8-8.2-14.9-12.3-24.4-12.3c-9.4,0-17.6,4.4-24.8,13.1C1824.5,101.7,1820.9,114.4,1820.9,131.1z"
        />
        <path
          d="M2025,195.2v-19c-10.1,14.6-23.8,22-41.1,22c-7.7,0-14.8-1.5-21.4-4.4s-11.6-6.6-14.8-11c-3.2-4.4-5.5-9.9-6.8-16.3
								c-0.9-4.3-1.3-11.1-1.3-20.5V65.6h22v71.9c0,11.5,0.4,19.2,1.3,23.2c1.4,5.8,4.3,10.3,8.8,13.6c4.5,3.3,10,4.9,16.6,4.9
								s12.8-1.7,18.6-5.1c5.8-3.4,9.9-8,12.3-13.8c2.4-5.8,3.6-14.3,3.6-25.3V65.6h22v129.6H2025z"
        />
        <path
          d="M2070.3,156.5l21.7-3.4c1.2,8.7,4.6,15.4,10.2,20s13.4,7,23.4,7c10.1,0,17.6-2.1,22.5-6.2c4.9-4.1,7.3-8.9,7.3-14.5
								c0-5-2.2-8.9-6.5-11.7c-3-2-10.5-4.4-22.5-7.4c-16.1-4.1-27.3-7.6-33.5-10.6c-6.2-3-10.9-7.1-14.2-12.3s-4.8-11-4.8-17.4
								c0-5.8,1.3-11.1,4-16.1c2.6-4.9,6.2-9,10.8-12.3c3.4-2.5,8.1-4.7,14-6.4c5.9-1.7,12.2-2.6,19-2.6c10.2,0,19.1,1.5,26.8,4.4
								c7.7,2.9,13.4,6.9,17,11.9s6.2,11.7,7.6,20.1l-21.5,2.9c-1-6.7-3.8-11.9-8.5-15.6c-4.7-3.7-11.3-5.6-19.8-5.6
								c-10.1,0-17.3,1.7-21.6,5c-4.3,3.3-6.5,7.2-6.5,11.7c0,2.8,0.9,5.4,2.7,7.7c1.8,2.4,4.6,4.3,8.4,5.9c2.2,0.8,8.7,2.7,19.4,5.6
								c15.5,4.2,26.4,7.5,32.5,10.2c6.1,2.6,11,6.5,14.5,11.5c3.5,5,5.2,11.3,5.2,18.8c0,7.3-2.1,14.2-6.4,20.7
								c-4.3,6.5-10.4,11.5-18.5,15c-8.1,3.5-17.2,5.3-27.3,5.3c-16.8,0-29.7-3.5-38.5-10.5C2078.4,180.6,2072.7,170.3,2070.3,156.5z"
        />
      </g>
    </svg>
  );
};

export default Logo;
