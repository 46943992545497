import * as React from "react";
import {
  Show,
  TextField,
  SimpleShowLayout,
  ReferenceField,
  SelectField,
} from "react-admin";
import { CreatedAndUpdatedField } from "../components/CustomFields";
import { DeleteForAdminToolbar } from "../components/CustomToolbars";
import { paymentProviders } from "../constants/paymentProviders";

const CashierShow = () => {
  return (
    <Show actions={<DeleteForAdminToolbar />}>
      <SimpleShowLayout>
        <TextField
          label={"common.fields.id"}
          source="cashierID"
          fullWidth={false}
        />
        <TextField
          label={"common.fields.name"}
          source={"name"}
          fullWidth={false}
        />
        <ReferenceField
          label="common.fields.event"
          source="eventID"
          reference="event"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField label="common.fields.cashier" source={"cashierRemoteID"} />
        <SelectField
          label={"common.fields.paymentProvider"}
          source={"paymentProviderID"}
          choices={paymentProviders.choices}
        />
      </SimpleShowLayout>
      <CreatedAndUpdatedField />
    </Show>
  );
};

export default CashierShow;
