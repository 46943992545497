import React from "react";
import { SVGProps } from "react";
import { useTheme } from "@mui/material/styles";

const PackageTypeIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg
      aria-hidden="true"
      data-prefix="fab"
      data-icon="github-alt"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="20"
      height="20"
      viewBox="0 0 128 128"
    >
      <path
        fill="#fff"
        d="M124 31.5L64 10.2 4 31.5 4 96.5 64 117.8 124 96.5z"
      />
      <path
        fill="#fff"
        d="M124 31.5L64 10.2 4 31.5 4 96.5 64 117.8 124 96.5zM124 31.5L64 10.2"
      />
      <path
        fill="#6e6e6f"
        d="M64,120.8c-0.3,0-0.7-0.1-1-0.2L3,99.3c-1.2-0.4-2-1.6-2-2.8v-65c0-1.3,0.8-2.4,2-2.8L63,7.4 c1.6-0.6,3.3,0.3,3.8,1.8c0.6,1.6-0.3,3.3-1.8,3.8L7,33.6v60.8l58,20.5c1.6,0.6,2.4,2.3,1.8,3.8C66.4,120,65.2,120.8,64,120.8z"
      />
      <path fill="#fff" d="M124 31.5L64 10.2M124 31.5L64 10.2" />
      <path
        fill="#6e6e6f"
        d="M127,31.5c0-1.2-0.8-2.4-2-2.8l-33.8-12c-0.8-0.3-1.6-0.2-2.3,0.1C87.8,17.3,29,38.1,29,38.1 c-1.5,0.5-2.5,1.9-2.5,3.5v14c0,1.3,0.8,2.4,2,2.8l7.8,2.8c2,0.7,4-0.7,4-2.8V46.7c0-1.6,1-3,2.5-3.5l53.1-18.4l19.2,6.8L63,49.9 c0,0,0,0,0,0c-1.1,0.4-2,1.5-2,2.8v60.8L7,94.4V33.6l58-20.5c1.6-0.6,2.4-2.3,1.8-3.8c-0.6-1.6-2.3-2.4-3.8-1.8L3,28.7 c-1.2,0.4-2,1.6-2,2.8v65c0,1.3,0.8,2.4,2,2.8l60,21.2c0,0,0,0,0,0c0.3,0.1,0.7,0.2,1,0.2c0.3,0,0.7-0.1,1-0.2l60-21.2 c1.2-0.4,2-1.6,2-2.8L127,31.5C127,31.5,127,31.5,127,31.5z"
      />
    </svg>
  );
};

export default PackageTypeIcon;
