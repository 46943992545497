/* eslint react/jsx-key: off */
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  useTranslate,
} from "react-admin";
import Grid from "@mui/material/Grid";
import { CancelButtonToolbar } from "../components/CustomToolbars";

const SmartmatEdit = () => {
  const translate = useTranslate();
  const posTip = translate(`resources.smartmats.tips.posid`);
  const nameTip = translate("common.tips.devicename");
  return (
    <Edit>
      <SimpleForm redirect="show" toolbar={<CancelButtonToolbar />}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput
              label={"common.fields.id"}
              source={"deviceID"}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              label={"common.fields.remotePosID"}
              source={"remotePosID"}
              title={posTip}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput source={"eventID"} reference={"event"}>
              <SelectInput
                label={"common.fields.event"}
                optionText={"name"}
                required
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              label={"common.fields.name"}
              source={"name"}
              title={nameTip}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default SmartmatEdit;
